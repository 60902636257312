import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeActiveCompany, setIsAddressModalOpen } from "../../../redux/reducer/slices/companiesSlice";
import { clearCart } from "../../../redux/reducer/slices/cartSlice";
import styled from "styled-components";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { AddressIcon, CheckIcon } from "../../../components/Icons";
import Modal from "../../../components/Modal";

const AddressButton = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentParams = new URLSearchParams(location.search);
    const { isAuth } = useSelector((state) => state.profile);
    const { getCompaniesStatus, companies, activeCompany, changeActiveCompanyStatus } = useSelector((state) => state.companies);
    const { products } = useSelector((state) => state.cart);
    const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);
    const [companySelected, setCompanySelected] = useState(null);
    const [isShowQuestion, setIsShowQuestion] = useState(false);
    const [isAlreadyAsked, setIsAlreadyAsked] = useState(() => {
        return sessionStorage.getItem('isAlreadyAsked') === 'true';
    });
    const [isAddressConfirmed, setIsAddressConfirmed] = useState(() => {
        return sessionStorage.getItem('isAddressConfirmed') === 'true';
    });
    const [questionType, setQuestionType] = useState(""); // "" | "addAddress" | "confirmAddress" | "selectAddress"

    useEffect(() => {
        if (getCompaniesStatus === 'fulfilled') {
            if (companies.length === 0) {
                setQuestionType("addAddress");
                if (!isAlreadyAsked) {
                    setIsShowQuestion(true);
                }
            } else if (companies.length > 0) {
                const matchCompany = companies.find(company => company.type === "1");
                if (matchCompany && !isAddressConfirmed) {
                    setQuestionType("confirmAddress");
                    if (!isAlreadyAsked) {
                        setIsShowQuestion(true);
                    }
                    sessionStorage.setItem('isAddressConfirmed', true);
                    setIsAddressConfirmed(true);
                } else {
                    setQuestionType("selectAddress");
                }
            }

            sessionStorage.setItem('isAlreadyAsked', true);
            setIsAlreadyAsked(true);
        }
    }, [companies]);

    const handleButtonClick = () => {
        setIsShowQuestion(true)
    };
    
    const handleConfirmClearCart = () => {
        navigate({
            pathname: '/',
            search: currentParams.toString(),
        });
        dispatch(changeActiveCompany(companySelected));
        setIsShowQuestion(false);
        dispatch(clearCart());
        setIsClearCartModalOpen(false);
        setCompanySelected(null);
    };

    const handleShowAddressList = () => {
        setQuestionType("selectAddress");
    };

    const handleAddAddress = () => {
        dispatch(setIsAddressModalOpen());
    };
    
    const handleChangeAddress = (company) => {
        if (products.length > 0) {
            setIsClearCartModalOpen(true);
            setCompanySelected(company);
        } else {
            navigate({
                pathname: '/',
                search: currentParams.toString(),
            });
            dispatch(changeActiveCompany(company));
            setIsShowQuestion(false);
        }
    };

    const handleCancel = () => {
        setIsShowQuestion(false);
        if (companies.length === 0) {
            setQuestionType("addAddress");
        } else if (companies.length > 0) {
            setQuestionType("selectAddress");
        }
    };

    if (!isAuth) {
        return null;
    }
    
    return (
        <AddressSelectorWrapper>
            <StyledButton onClick={handleButtonClick}>
                {getCompaniesStatus === 'pending' || changeActiveCompanyStatus === 'pending' ? (
                    <Loader/>
                ) : (
                    <>
                        {companies.length > 0 && activeCompany ? (
                            <ButtonContent>
                                <div>
                                <AddressIcon/>
                                </div>
                                <ButtonTitle>
                                    <span>{activeCompany.name && activeCompany.name}</span>
                                    <p>
                                        {activeCompany.address.street && `${activeCompany.address.street} `}
                                        {activeCompany.address.house && `${activeCompany.address.house}`}
                                        {activeCompany.address.floor && `, эт. ${activeCompany.address.floor},`}
                                        {activeCompany.address.flat && ` кв ${activeCompany.address.flat}`}
                                    </p>
                                </ButtonTitle>
                            </ButtonContent>
                        ) : (
                            <>
                                <AddressIcon/>
                                <p>Укажите адрес доставки</p>
                            </>
                        )}
                    </>
                )}
            </StyledButton>

            {getCompaniesStatus !== 'pending' && isShowQuestion && (
                <>
                    <Backdrop onClick={handleCancel}/>
                    {questionType === "addAddress" ? (
                        <QuestionWrapper>
                            <Title style={{marginBottom: '10px'}}>Добавим адрес?</Title>
                            <Text>Добавив адрес доставки, вы увидите доступные кухни и условия доставки</Text>
                            <Actions>
                                <Button style={{flex: 1}} onClick={handleCancel}>Нет</Button>
                                <Button style={{flex: 1}} type="primary" onClick={handleAddAddress}>Да</Button>
                            </Actions>
                        </QuestionWrapper>
                    ) : questionType === "confirmAddress" && companies.length > 0 ? (
                        (() => {
                            const matchCompany = companies.find(company => company.type === "1");

                            return (
                                <QuestionWrapper>
                                    <Text>Заказ доставить на этот адрес?</Text>
                                    <Address>
                                        {matchCompany.address.street && `${matchCompany.address.street} `}
                                        {matchCompany.address.house && `${matchCompany.address.house}`}
                                        {matchCompany.address.floor && `, эт. ${matchCompany.address.floor},`}
                                        {matchCompany.address.flat && ` кв ${matchCompany.address.flat}`}
                                    </Address>
                                    <Actions>
                                        <Button style={{flex: 1}} onClick={handleShowAddressList}>Нет</Button>
                                        <Button style={{flex: 1}} type="primary" onClick={handleCancel}>Да</Button>
                                    </Actions>
                                </QuestionWrapper>
                            );
                        })()
                    ) : questionType === "selectAddress" ? (
                        <QuestionWrapper style={{padding: '0 0 24px'}}>
                            <List>
                                {companies.length > 0 ? (
                                    companies.map((company) => (
                                        <ListItem key={company.id} onClick={() => handleChangeAddress(company)}>
                                            <div>
                                                <span>{company.name && company.name}</span>
                                                <p>
                                                    {company.address.street && `${company.address.street} `}
                                                    {company.address.house && `${company.address.house}`}
                                                    {company.address.floor && `, эт. ${company.address.floor},`}
                                                    {company.address.flat && ` кв ${company.address.flat}`}
                                                </p>
                                            </div>
                                            {activeCompany && company.id === activeCompany.id &&
                                                <CheckIcon color={'#EA394B'}/>
                                            }
                                        </ListItem>
                                    ))
                                ) : (
                                    'У вас нет адресов'
                                )}
                            </List>
                            <ListActions>
                                <Button style={{flex: 1}} onClick={handleAddAddress}>Новый адрес</Button>
                            </ListActions>
                        </QuestionWrapper>
                    ) : null}
                </>
            )}

            <Modal isOpen={isClearCartModalOpen} onClose={() => setIsClearCartModalOpen(false)}>
				<ModalWrapper>
					<p style={{marginBottom: '12px'}}>При смене адреса ваша корзина будет очищена</p>
					<Title>Продолжить?</Title>
					<ModalActions>
						<Button style={{flex: 1}} type='primary' onClick={handleConfirmClearCart}>Да</Button>
						<Button style={{flex: 1}} onClick={() => setIsClearCartModalOpen(false)}>Нет</Button>
					</ModalActions>
				</ModalWrapper>
			</Modal>
        </AddressSelectorWrapper>
    );
};

export default AddressButton;

const AddressSelectorWrapper = styled.div`
    position: relative;
    width: 100%;
    min-width: 200px;
    max-width: 380px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    display: block;
    @media (max-width: 798px) {
        padding: 0 10px;
        height: 45px;
        min-height: 45px;
        border-radius: 16px;
        overflow: hidden;
    }
`;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ButtonTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(100% - 40px);
    span {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {
        width: 100%;
        font-size: 16px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media (max-width: 798px) {
        align-items: flex-start;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
`;

const QuestionWrapper = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 16px;
    padding: 24px 32px;
    width: fit-content;
    width: 380px;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: 10;
    @media (max-width: 798px) {
        padding: 16px;
        width: 320px;
    }
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
`;

const Text = styled.div`
    font-size: 15px;
    font-weight: 400;
`;

const Address = styled.div`
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
`;

const List = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
`;

const ListItem = styled.div`
    min-height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 0 32px;
    cursor: pointer;
    transition: all 0.3s ease;
    span {
        font-size: 16px;
        font-weight: 600;
    }
    p{
        font-size: 16px;
    }
    &:hover {
        background: #F3F3F3;
    }
    @media (max-width: 798px) {
        padding: 0 16px;
    }
`;

const Actions = styled.div`
    margin-top: 16px;
    display: flex;
    gap: 16px;
`;

const ListActions = styled(Actions)`
    padding: 0 32px;
    @media (max-width: 798px) {
        padding: 0 16px;
    }
`;

const ModalWrapper = styled.div`
	max-width: 420px;
`;

const ModalActions = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 24px;
`;
