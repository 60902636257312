import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderHeight, setIsCityModalClose } from "../../redux/reducer/slices/commonSlice";
import { setIsAuthModalClose, setIsAuthQuestionModalClose } from '../../redux/reducer/slices/profileSlice';
import { setIsAddressModalClose } from "../../redux/reducer/slices/companiesSlice";
import { Wrapper, Content } from '../styles';
import Header from "../../containers/header/Header";
import Footer from "../../containers/footer/Footer";
import FloatingSocialButtons from "../../components/FloatingSocialButtons";
import { Message } from "../../components/Message";
import Modal from "../../components/Modal";
import AuthForm from "../../containers/forms/authForm/AuthForm";
import SelectCityForm from "../../containers/forms/selectCityForm/SelectCityForm";
import AddressSelectorForm from "../../containers/forms/addressSelectorForm/AddressSelectorForm";
import AuthQuestionForm from "../../containers/forms/authForm/AuthQuestionForm";
import { setGeocodeAddress, setGeocodeCoords } from "../../redux/reducer/slices/mapSlice";
import styled from "styled-components";
import { useScroll } from "../../hooks/ScrollContext";
import { useInactivity } from "../../hooks/useInactivity";
import InactivityForm from "../../containers/forms/inactivityForm/InactivityForm";

const SCROLL_KEY = "mainPageScroll";

const excludedHeaderPages = ['/app', '/price', '/delivery', '/kitchens', '/testdrive'];
const excludedFooterPages = ['/app', '/delivery', '/kitchens', '/testdrive'];

const MainLayout = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { device, headerHeight, isCityModalOpen } = useSelector((state) => state.common);
    const { isAuthModalOpen, isAuthQuestionModalOpen } = useSelector((state) => state.profile);
	const { isAddressModalOpen, saveCompanyStatus } = useSelector((state) => state.companies);
    const { kitchens } = useSelector(state => state.kitchens);
    const { wrapperRef } = useScroll();
    const { inactivity } = useInactivity();

    const haveHeader = !excludedHeaderPages.includes(location.pathname);
    const haveFooter = (!excludedFooterPages.includes(location.pathname) && device === 'desktop') || (location.pathname === '/' && device === 'mobile');
    
    // Функция восстановления скролла
    const restoreScroll = () => {
        if (wrapperRef.current && location.pathname === '/') {
            const savedScrollPosition = sessionStorage.getItem(SCROLL_KEY);
            if (savedScrollPosition) {
                requestAnimationFrame(() => {
                    wrapperRef.current.scrollTo(0, parseInt(savedScrollPosition, 10));
                });
            }
        }
    };

    useEffect(() => {
        if (!haveHeader) {
            dispatch(setHeaderHeight(0));
        }
    }, [haveHeader]);

    // Восстанавливаем скролл при загрузке страницы
    useEffect(() => {
        restoreScroll();
    }, [location.pathname]);

    // Восстанавливаем скролл при загрузке кухонь
    useEffect(() => {
        if (kitchens?.length > 0) {
            restoreScroll();
        }
    }, [kitchens]);

    // Сохраняем скролл при уходе с главной страницы или обновлении
    useEffect(() => {
        const handleSaveScroll = () => {
            if (location.pathname === '/' && wrapperRef.current) {
                sessionStorage.setItem(SCROLL_KEY, wrapperRef.current.scrollTop);
            }
        };

        window.addEventListener('beforeunload', handleSaveScroll);
        return () => {
            handleSaveScroll();
            window.removeEventListener('beforeunload', handleSaveScroll);
        };
    }, [location.pathname]);

    // Сбрасываем скролл на всех страницах кроме главной
    useEffect(() => {
        if (wrapperRef.current && location.pathname !== '/') {
            wrapperRef.current.scrollTo(0, 0);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (saveCompanyStatus === 'fulfilled') {
            dispatch(setIsAddressModalClose());
        }
    }, [saveCompanyStatus]);

    return (
        <>
            <Wrapper ref={wrapperRef}>
                {haveHeader && <Header/>}

                <Content style={{ paddingTop: `${headerHeight}px` }}>
                    {children}
                </Content>

                {haveFooter && <Footer/>}
            </Wrapper>

            <Modal isOpen={isAuthModalOpen} onClose={() => {dispatch(setIsAuthModalClose())}}><AuthForm/></Modal>
            <Modal isOpen={isAddressModalOpen} onClose={() => {dispatch(setIsAddressModalClose()); dispatch(setGeocodeAddress(null)); dispatch(setGeocodeCoords(null));}}><AddressSelectorForm/></Modal>
            <Modal isOpen={isCityModalOpen} onClose={() => {dispatch(setIsCityModalClose())}}><SelectCityForm/></Modal>
            <Modal isOpen={isAuthQuestionModalOpen} onClose={() => {dispatch(setIsAuthQuestionModalClose())}}><AuthQuestionForm/></Modal>
            <Modal isOpen={inactivity} onClose={() => window.location.reload()}><InactivityForm onClick={() => window.location.reload()}/></Modal>

            <Message/>

            <FloatingSocialButtonsWrapper>
                <FloatingSocialButtons/>
            </FloatingSocialButtonsWrapper>
        </>
    );
};

export default MainLayout;

const FloatingSocialButtonsWrapper = styled.div`
    @media (max-width: 798px) {
        display: none;
    }
`;