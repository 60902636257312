import React from "react";
import styled from "styled-components";
import Button from "../../../components/Button";

const InactivityForm = ({ onClick }) => {
    return (
        <Wrapper>
            <Title>Вы еще тут?💡</Title>
            <Text>Продолжим покупки! 🚀</Text>
            <Button style={{width: '100%'}} type='primary' onClick={onClick}>Ок</Button>
        </Wrapper>
    );
}

export default InactivityForm;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
`;

const Text = styled.div`
    
`;
