import React from "react";
import { createRoot } from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from './redux/configureStore';
import 'react-app-polyfill/ie11';
import moment from 'moment';
import 'moment/locale/ru';
import App from "./core/App";

moment.locale('ru');

const currentAppVersion = '1.00.16';
const storedAppVersion = localStorage.getItem('appVersion');

if (storedAppVersion !== currentAppVersion) {
    // Очистка localStorage
    localStorage.clear();
    localStorage.setItem('appVersion', currentAppVersion);

    // Очистка sessionStorage
    sessionStorage.clear();

    // Очистка cookies
    document.cookie.split(";").forEach((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });

    // Перезагрузка страницы
    document.location.reload();
}

localStorage.removeItem('payProcessing');

if (document.location.protocol === 'https:') {
	caches.keys().then(function(names) {
		for (let name of names) {
			console.log(name, 'cache name')
			caches.delete(name).then(res => console.log('cache is cleared?', res));
		}
	})
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter basename={"/"}>
            <App />
        </BrowserRouter>
    </Provider>
);
