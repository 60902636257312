import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BurgerIcon, LogoIcon } from '../../components/Icons';
// import InputWithButton from "../../components/InputWithButton";
import AddressButton from "./components/AddressButton";
import Button from "../../components/Button";
import FloatingSocialButtons from "../../components/FloatingSocialButtons";
import ProfileButton from "./components/ProfileButton";
import CartButton from "./components/CartButton";
import MobileSidebar from "./components/MobileSidebar";
import { Container } from "../../core/styles";
import Title from "../../components/Title";
import AppReco from "./components/AppReco";
import { setHeaderHeight } from "../../redux/reducer/slices/commonSlice";

const Header = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentParams = new URLSearchParams(location.search);
	const { isAuth } = useSelector((state) => state.profile);
	const { currentPartner } = useSelector((state) => state.partner);
	const { currentKitchen } = useSelector((state) => state.kitchens);
	const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
	const [isShowAppReco, setIsShowAppReco] = useState(localStorage.getItem('appRecoShowed') !== 'true');

	const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

	const getHeaderHeight = () => {
        const desktopHeight = document.querySelector("#desktop-header")?.offsetHeight || 0;
        const mobileHeight = document.querySelector("#mobile-header")?.offsetHeight || 0;
        return window.innerWidth > 798 ? desktopHeight : mobileHeight;
    };

    useEffect(() => {
		const updateHeight = () => dispatch(setHeaderHeight(getHeaderHeight())); // Диспатч в стор
	
		updateHeight();
		window.addEventListener("resize", updateHeight);
	
		return () => {
			window.removeEventListener("resize", updateHeight);
		};
	}, [isShowAppReco]);

	return (
		<>
			<DesktopWrapper id="desktop-header">
				<Container>
					<HeaderWrapper>
						<LeftCol>
							<LogoWrapper>
								{currentPartner &&
									<Link to={"/"}>
										<Title style={{whiteSpace: 'nowrap'}}>{currentKitchen?.name}</Title>
										{/* {currentKitchen?.cityName && <Title style={{whiteSpace: 'nowrap'}} level={5}>Доставка обедов в {currentKitchen?.cityName}</Title>} */}
									</Link>
								}
								{(
									subdomain === 'sitiy' ||
									subdomain === 'sitiy-test' ||
									subdomain === 'localhost'
								) &&
									<Link to={"/"}>
										<LogoIcon/>
									</Link>
								}
							</LogoWrapper>

							{currentPartner &&
								<PartnerLinks>
									<PartnerLink to={"/about"}>О нас</PartnerLink>
									<PartnerLink to={"/delivery"}>Зоны доставки</PartnerLink>
								</PartnerLinks>
							}

							{/* <InputWithButton
								icon={SearchIcon}
								placeholder="Поиск блюд и кухонь"
								buttonText="Найти"
								onClick={handleSearch}
							/> */}

							<AddressButton/>
						</LeftCol>

						<RightCol>
							<CartButton/>
							<ProfileButton/>
						</RightCol>
					</HeaderWrapper>
				</Container>
			</DesktopWrapper>

			<MobileWrapper id="mobile-header">
				{isShowAppReco && !currentPartner && <AppReco onClose={() => setIsShowAppReco(false)}/>}

				<Content>
					<Button style={{flex: 0}} size='small' type='icon' onClick={() => setIsMobileSidebarOpen(true)}><BurgerIcon/></Button>
					
					{location.pathname !== '/' ?
						<MobileLogoWrapper>
							<Link to={{ pathname: "/", search: currentParams.toString() }}>
								{currentPartner &&
									<Link to={"/"}>
										<Title>{currentKitchen?.name}</Title>
									</Link>
								}
								{(
									subdomain === 'sitiy' ||
									subdomain === 'sitiy-test' ||
									subdomain === 'localhost'
								) &&
									<Link to={"/"}>
										<LogoIcon/>
									</Link>
								}
							</Link>
						</MobileLogoWrapper>
					: isAuth ? <AddressButton/> : <ProfileButton/>}

					<WrapperFloatingSocialButtons>
						<FloatingSocialButtons/>
					</WrapperFloatingSocialButtons>

					<CartButton/>

					<MobileSidebar isOpen={isMobileSidebarOpen} onClose={() => setIsMobileSidebarOpen(false)}/>
				</Content>
			</MobileWrapper>
		</>
	);
};

export default Header;

const DesktopWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
	background-color: #ffffff;
	@media (max-width: 798px) {
		display: none;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 60px;
	background-color: #ffffff;
	height: 100px;
`;

const LeftCol = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	gap: 20px;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;
	@media (max-width: 1024px) {
		display: none;
	}
`;

const PartnerLinks = styled.div`
	margin-left: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
	@media (max-width: 798px) {
		display: none;
	}
`;

const PartnerLink = styled(Link)`
	color: var(--color-text);
	text-decoration: underline;
	white-space: nowrap;
`;

const RightCol = styled.div`
	flex: 0;
	display: flex;
	align-items: center;
	gap: 20px;
	@media (max-width: 798px) {
		gap: 10px;
	}
`;

const MobileWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
	display: none;
	flex-direction: column;
	@media (max-width: 798px) {
		display: flex;
	}
`;

const MobileLogoWrapper = styled.div`
	display: none;
	@media (max-width: 1024px) {
		display: flex;
		align-items: center;
		width: fit-content;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	padding: 24px 10px 12px;
	width: 100%;
	background-color: #ffffff;
`;

const WrapperFloatingSocialButtons = styled.div`
	position: relative;
	min-width: 45px;
	max-width: 45px;
	height: 45px;
`;