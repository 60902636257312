const config = {
    appName: 'Сытый',
    supportEmail: 'support@example.com',
    server: 'https://menuforme.online/api/',

    yandexMapApiKey: 'YOUR_API_KEY',
    yandexMapGeocodeApiKey: 'YOUR_API_KEY',
    
    socialLinks: {
        telegram: 'https://t.me/sitiy_ofis',
        vk: 'https://vk.com/sitiy_ru',
        instagram: 'https://www.instagram.com/sitiy_tlt/',
    },

    appLinks: {
        appstore: 'https://itunes.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950?l=ru&ls=1&mt=8',
        playmarket: 'https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice',
        rustore: 'https://apps.rustore.ru/app/air.com.menu4me.dinner.sitiyoffice',
    },
};

export default config;
