import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import moment from 'moment';
import _ from 'underscore';

export const getDataForMainPage = createAsyncThunk(
    'kitchens/getDataForMainPage',
    async (_, { rejectWithValue }) => {
        const currentCity = JSON.parse(localStorage.getItem('currentCity'));
        const cityId = currentCity ? currentCity.id : '18';
        const data = {
            city: {
                id: cityId,
            },
        }

        try {
            const response = await request("DataForMainPage", "get", data);

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const getKitchens = createAsyncThunk(
    'kitchens/getKitchens',
    async (props, { rejectWithValue }) => {
        const currentCity = JSON.parse(localStorage.getItem('currentCity'));
        const cityId = currentCity ? currentCity.id : '18';

        const data = {
            withoutToken: props?.withoutToken ? true : undefined,
            city: {
                id: cityId,
            },
        }

        try {
            const response = await request('AppDinnerController', 'GetAccountsForAddress', data);

            if (response.result && response.data && response.data.accounts) {
                const { accounts, tags } = response.data;

                const tagsMap = tags.reduce((acc, tag) => {
                    acc[tag.id] = tag;
                    return acc;
                }, {});

                const enrichedAccounts = accounts
                    .filter(account => +account.storeType === 1)
                    .map(account => {
                        const accountTags = Array.isArray(account.tags) ? account.tags : [];

                        return {
                            ...account,
                            tagDetails: accountTags
                                .map(tagId => {
                                    const tag = tagsMap[tagId];
                                    if (!tag) console.warn(`⚠️ Тег ${tagId} не найден в tagsMap`);
                                    return tag;
                                })
                                .filter(Boolean),
                        };
                    });

                return enrichedAccounts;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const getKitchenById = createAsyncThunk(
    'kitchens/getKitchenById',
    async (props, { getState, rejectWithValue }) => {
        const { currentPartner } = getState().partner;

        const data = {
            withoutToken: props?.withoutToken ? true : undefined,
            accountId: currentPartner ? currentPartner : props?.accountId ? props.accountId : undefined,
        }

        try {
            const response = await request('AppDinnerController', 'GetAccountById', data);

            if (response.result && response.data && response.data.accounts) {
                const { accounts, tags } = response.data;

                const tagsMap = tags.reduce((acc, tag) => {
                    acc[tag.id] = tag;
                    return acc;
                }, {});

                const enrichedAccounts = accounts
                    .filter(account => +account.storeType === 1)
                    .map(account => {
                        const accountTags = Array.isArray(account.tags) ? account.tags : [];

                        return {
                            ...account,
                            tagDetails: accountTags
                                .map(tagId => {
                                    const tag = tagsMap[tagId];
                                    if (!tag) console.warn(`⚠️ Тег ${tagId} не найден в tagsMap`);
                                    return tag;
                                })
                                .filter(Boolean),
                        };
                    });

                return enrichedAccounts;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const getMenu = createAsyncThunk(
    'kitchens/getMenu',
    async ({ owner, date, period }, { rejectWithValue }) => {
        let data = {
            owner: { id: owner },
            date: { dateString: date },
            period: { id: period },
        };

        try {
            let response = await request('AppDinnerController', 'GetMenu', data);

            if (response.result) {
                const data = response.data;
                let categories = [];

                if (
                    data &&
                    data.menu &&
                    data.menu.root &&
                    data.menu.root.sectionsAsArray &&
                    data.menu.root.sectionsAsArray.length > 0 &&
                    data.menu.sections &&
                    _.size(data.menu.sections) > 0
                ) {
                    data.menu.root.sectionsAsArray.forEach(sectionId => {
                        let section = data.menu.sections[sectionId];
                        if (section && _.size(section.elementsAsArray) > 0) {
                            categories.push(section);
                        }
                    });
                }

                if (
                    categories.length &&
                    data &&
                    data.menu &&
                    data.menu.elements &&
                    _.size(data.menu.elements) > 0
                ) {
                    let products = [];
                    let sectionsArray = [];

                    for (let key in data.menu.elements) {
                        let mods = [];

                        if (_.size(data.menu.modifiers) > 0 && data.menu.modifiers.hasOwnProperty(key)) {
                            mods = data.menu.modifiers[key].map(mod => data.menu.elements[mod.id]);
                        }

                        if (_.size(data.menu.elements[key].properties) > 0) {
                            let properties = data.menu.elements[key].properties.map(i => {
                                return data.menu.properties[i];
                            })
                            products.push({ ...data.menu.elements[key], modifiers: mods, properties });
                        } else {
                            products.push({ ...data.menu.elements[key], modifiers: mods });
                        }
                    }

                    categories.forEach(section => {
                        const rawData = [];

                        section.elementsAsArray.forEach(elementId => {
                            const element = products.find(product => product.id === elementId);
                            if (element) {
                                rawData.push(element);
                            }
                        });

                        sectionsArray.push({
                            title: section.name,
                            data: rawData,
                        });
                    });

                    const preparedSections = sectionsArray.map((item, index) => ({ ...item, index }));

                    return preparedSections;
                } else {
                    return null;
                }
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

const initialState = {
    getDataForMainPageStatus: null,
    stories: [],

    getKitchensStatus: null,
    kitchens: null,
    currentKitchen: null,

    getMenuStatus: null,
    menu: null,

    selectedDay: moment().format('YYYY-MM-DD'),
    currentPeriod: 1,
};

const kitchensSlice = createSlice({
    name: 'kitchens',
    initialState,
    extraReducers: (builder) => {
        builder
            // getDataForMainPage
            .addCase(getDataForMainPage.pending, (state) => {
                state.getDataForMainPageStatus = 'pending';
            })
            .addCase(getDataForMainPage.fulfilled, (state, action) => {
                state.getDataForMainPageStatus = 'fulfilled';
                state.stories = action.payload.stories;
            })
            .addCase(getDataForMainPage.rejected, (state) => {
                state.getDataForMainPageStatus = 'rejected';
            })

            // getKitchens
            .addCase(getKitchens.pending, (state) => {
                state.getKitchensStatus = 'pending';
            })
            .addCase(getKitchens.fulfilled, (state, action) => {
                state.getKitchensStatus = 'fulfilled';
                state.kitchens = action.payload;
            })
            .addCase(getKitchens.rejected, (state) => {
                state.getKitchensStatus = 'rejected';
            })

            // getKitchenById
            .addCase(getKitchenById.pending, (state) => {
                state.getKitchensStatus = 'pending';
            })
            .addCase(getKitchenById.fulfilled, (state, action) => {
                state.getKitchensStatus = 'fulfilled';
                state.currentKitchen = action.payload[0];
            })
            .addCase(getKitchenById.rejected, (state) => {
                state.getKitchensStatus = 'rejected';
            })

            // getMenu
            .addCase(getMenu.pending, (state) => {
                state.getMenuStatus = 'pending';
            })
            .addCase(getMenu.fulfilled, (state, action) => {
                state.getMenuStatus = 'fulfilled';
                state.menu = action.payload;
            })
            .addCase(getMenu.rejected, (state) => {
                state.getMenuStatus = 'rejected';
                state.menu = null;
            })
    },
    reducers: {
        setCurrentKitchen: (state, action) => {
            state.currentKitchen = action.payload;
        },
        setSelectedDay: (state, action) => {
            state.selectedDay = action.payload;
        },
        setCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload;
        },
    },
});

export const {
    setCurrentKitchen,
    setSelectedDay,
    setCurrentPeriod,
} = kitchensSlice.actions;

export default kitchensSlice.reducer;
