import React from "react";
import styled from "styled-components";
import config from "../../core/config";
import { Container } from "../../core/styles";
import Title from "../../components/Title";
import mastercard from "./images/card/mastercard.png";
import visa from "./images/card/visa.png";
import mir from "./images/card/mir.png";

import qrcode from './images/apps/qrcode.jpg';
import googlePlay from './images/apps/googleplay.jpg';
import appStore from './images/apps/appstore.jpg';
import ruStore from './images/apps/rustore.jpg';

import { TGIcon, VBIcon, VKIcon, WSIcon } from '../../components/Icons';
// import telegram from './images/social/telegram.jpg';
// import vk from './images/social/vk.jpg';
// import instagram from './images/social/instagram.jpg';

const Footer = () => {
    const links = [
        { name: 'О сервисе', url: '/about' },
        { name: 'Условия доставки', url: '/delivery' },
        { name: 'Кухням', url: '/kitchens' },
        { name: 'Организациям', url: '/organizations' },
    ]

    return (
        <>
        {/* <Subscribe/> */}
            <Wrapper>
                <Container>
                    <Grid>
                        <NameBlock>
                            <Title>Сытый</Title>
                            <p>Сервис доставки еды 2018-{new Date().getFullYear()}</p>
                        </NameBlock>

                        <LinksBlock>
                            {links.map((link, idx) => (
                                <a key={idx} href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                            ))}
                        </LinksBlock>

                        <AppsBlock>
                            <Title>Скачайте приложение</Title>
                            <AppsGrid>
                                <AppQR>
                                    <img width="132" height="132" src={qrcode} alt="qrcode"/>
                                </AppQR>
                                <AppLink href={config.appLinks.appstore}>
                                    <img width="160" height="60" src={appStore} alt="appStore"/>
                                </AppLink>
                                <AppLink href={config.appLinks.playmarket}>
                                    <img width="160" height="60" src={googlePlay} alt="googlePlay"/> 
                                </AppLink>
                                <AppLink href={config.appLinks.rustore}>
                                    <img width="160" height="60" src={ruStore} alt="ruStore"/>
                                </AppLink>
                            </AppsGrid>
                        </AppsBlock>

                        <InfoBlock>
                            <Info>
                                <Title style={{whiteSpace: 'nowrap'}}>
                                    <a href="tel:88002006924" style={{ textDecoration: 'none', color: 'inherit' }}>8 800 200-69-24</a>
                                </Title>
                                <p>звонок бесплатный</p>
                                <PaymentIcons>
                                    <img width="56" height="16" src={mir} alt="МИР"/>
                                    <img width="49" height="16" src={visa} alt="Visa"/>
                                    <img width="25" height="20" src={mastercard} alt="MasterCard"/>
                                </PaymentIcons>
                            </Info>
                            <SocialLinks>
                                <IconButton href='https://vk.com/sitiy_ru' target="_blank" rel="noopener noreferrer" bgColor="#0079FF">
                                    <VKIcon/>
                                </IconButton>
                                <IconButton href='https://telegram.me/sitiy_support_bot' target="_blank" rel="noopener noreferrer" bgColor="#44B3E3">
                                    <TGIcon/>
                                </IconButton>
                                <IconButton href='viber://pa?chatURI=sitiy' target="_blank" rel="noopener noreferrer" bgColor="#9156B4">
                                    <VBIcon/>
                                </IconButton>
                                <IconButton href='https://wa.me/79914590879' target="_blank" rel="noopener noreferrer" bgColor="#69C060">
                                    <WSIcon/>
                                </IconButton>
                            </SocialLinks>
                        </InfoBlock>
                    </Grid>
                </Container>
            </Wrapper>

            <Container>
                <FooterNote>
                    © Сытый - Сервис доставки еды 2018-{new Date().getFullYear()}
                </FooterNote>
            </Container>
        </>
    );
};

export default Footer;

const Wrapper = styled.div`
    flex: 0 1 auto;
    padding: 50px 0;
    background: #EDEDED;
    border: 1px solid #E0E0E0;
    @media(max-width: 798px) {
        padding: 24px 0;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media(max-width: 798px) {
        gap: 24px;
    }
`;

const NameBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media(max-width: 798px) {
        display: none;
    }
`;

const LinksBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 32px 48px;
    flex: 1;
    a {
        color: #7c7c7c;
        text-decoration: none;
        white-space: nowrap;
    }
    @media(max-width: 798px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-items: center;
        gap: 16px;
    }
`;

const AppsBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 798px) {
        align-items: center;
    }
`;
const AppsGrid= styled.div`
    display: grid;
    grid-template-columns: 132px 160px 160px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 12px;
    max-width: 470px;
    @media(max-width: 798px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-items: center;
    }
`;
const AppQR = styled.div`
    grid-row: span 2; /* QR-код занимает 2 строки */
    width: 132px;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
`;
const AppLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    border-radius: 16px;
    overflow: hidden;
`;

const InfoBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    flex: 1;
    
    @media(max-width: 798px) {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media(max-width: 798px) {
        align-items: center;
    }
`;

const PaymentIcons = styled.div`
    display: flex;
    gap: 8px;
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 16px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 16px;
        overflow: hidden;
    }
`;

const IconButton = styled.a`
  position: relative;
  min-width: 60px;
  height: 60px;
  border-radius: 18px;
  background-color: ${(props) => props.bgColor || '#EA394B'};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff !important;
  text-decoration: none;
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const FooterNote = styled.div`
    display: none;
    @media(max-width: 798px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 16px 24px;
    }
`;
