import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

let showMessage;

export const Message = () => {
  const [messages, setMessages] = useState([]);

  showMessage = ({ type, title, body }) => {
    const id = Date.now();
    setMessages((prevMessages) => [
      ...prevMessages,
      { id, type, title, body }
    ]);

    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== id)
      );
    }, 3000);
  };

  return (
    <MessageWrapper>
      {messages.map((message) => (
        <MessageContainer key={message.id} type={message.type} className="show">
          <IconWrapper>
            {message.type === 'default' &&
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 21V16C14.5 15.4477 14.0523 15 13.5 15H10.5C9.94772 15 9.5 15.4477 9.5 16V21" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5 11V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V11" stroke="white"/>
                <path d="M4.62127 4.51493C4.80316 3.78737 4.8941 3.42359 5.16536 3.21179C5.43663 3 5.8116 3 6.56155 3H17.4384C18.1884 3 18.5634 3 18.8346 3.21179C19.1059 3.42359 19.1968 3.78737 19.3787 4.51493L20.5823 9.32938C20.6792 9.71675 20.7276 9.91044 20.7169 10.0678C20.6892 10.4757 20.416 10.8257 20.0269 10.9515C19.8769 11 19.6726 11 19.2641 11V11C18.7309 11 18.4644 11 18.2405 10.9478C17.6133 10.8017 17.0948 10.3625 16.8475 9.76781C16.7593 9.55555 16.7164 9.29856 16.6308 8.78457V8.78457C16.6068 8.64076 16.5948 8.56886 16.5812 8.54994C16.5413 8.49439 16.4587 8.49439 16.4188 8.54994C16.4052 8.56886 16.3932 8.64076 16.3692 8.78457L16.2877 9.27381C16.2791 9.32568 16.2747 9.35161 16.2704 9.37433C16.0939 10.3005 15.2946 10.9777 14.352 10.9995C14.3289 11 14.3026 11 14.25 11V11C14.1974 11 14.1711 11 14.148 10.9995C13.2054 10.9777 12.4061 10.3005 12.2296 9.37433C12.2253 9.35161 12.2209 9.32568 12.2123 9.27381L12.1308 8.78457C12.1068 8.64076 12.0948 8.56886 12.0812 8.54994C12.0413 8.49439 11.9587 8.49439 11.9188 8.54994C11.9052 8.56886 11.8932 8.64076 11.8692 8.78457L11.7877 9.27381C11.7791 9.32568 11.7747 9.35161 11.7704 9.37433C11.5939 10.3005 10.7946 10.9777 9.85199 10.9995C9.82887 11 9.80258 11 9.75 11V11C9.69742 11 9.67113 11 9.64801 10.9995C8.70541 10.9777 7.90606 10.3005 7.7296 9.37433C7.72527 9.35161 7.72095 9.32568 7.7123 9.27381L7.63076 8.78457C7.60679 8.64076 7.59481 8.56886 7.58122 8.54994C7.54132 8.49439 7.45868 8.49439 7.41878 8.54994C7.40519 8.56886 7.39321 8.64076 7.36924 8.78457V8.78457C7.28357 9.29856 7.24074 9.55555 7.15249 9.76781C6.90524 10.3625 6.38675 10.8017 5.75951 10.9478C5.53563 11 5.26905 11 4.73591 11V11C4.32737 11 4.12309 11 3.97306 10.9515C3.58403 10.8257 3.31078 10.4757 3.28307 10.0678C3.27239 9.91044 3.32081 9.71675 3.41765 9.32938L4.62127 4.51493Z" stroke="white"/>
              </svg>
            }
            {message.type === 'kitchen' &&
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 21V16C14.5 15.4477 14.0523 15 13.5 15H10.5C9.94772 15 9.5 15.4477 9.5 16V21" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5 11V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V11" stroke="white"/>
                <path d="M4.62127 4.51493C4.80316 3.78737 4.8941 3.42359 5.16536 3.21179C5.43663 3 5.8116 3 6.56155 3H17.4384C18.1884 3 18.5634 3 18.8346 3.21179C19.1059 3.42359 19.1968 3.78737 19.3787 4.51493L20.5823 9.32938C20.6792 9.71675 20.7276 9.91044 20.7169 10.0678C20.6892 10.4757 20.416 10.8257 20.0269 10.9515C19.8769 11 19.6726 11 19.2641 11V11C18.7309 11 18.4644 11 18.2405 10.9478C17.6133 10.8017 17.0948 10.3625 16.8475 9.76781C16.7593 9.55555 16.7164 9.29856 16.6308 8.78457V8.78457C16.6068 8.64076 16.5948 8.56886 16.5812 8.54994C16.5413 8.49439 16.4587 8.49439 16.4188 8.54994C16.4052 8.56886 16.3932 8.64076 16.3692 8.78457L16.2877 9.27381C16.2791 9.32568 16.2747 9.35161 16.2704 9.37433C16.0939 10.3005 15.2946 10.9777 14.352 10.9995C14.3289 11 14.3026 11 14.25 11V11C14.1974 11 14.1711 11 14.148 10.9995C13.2054 10.9777 12.4061 10.3005 12.2296 9.37433C12.2253 9.35161 12.2209 9.32568 12.2123 9.27381L12.1308 8.78457C12.1068 8.64076 12.0948 8.56886 12.0812 8.54994C12.0413 8.49439 11.9587 8.49439 11.9188 8.54994C11.9052 8.56886 11.8932 8.64076 11.8692 8.78457L11.7877 9.27381C11.7791 9.32568 11.7747 9.35161 11.7704 9.37433C11.5939 10.3005 10.7946 10.9777 9.85199 10.9995C9.82887 11 9.80258 11 9.75 11V11C9.69742 11 9.67113 11 9.64801 10.9995C8.70541 10.9777 7.90606 10.3005 7.7296 9.37433C7.72527 9.35161 7.72095 9.32568 7.7123 9.27381L7.63076 8.78457C7.60679 8.64076 7.59481 8.56886 7.58122 8.54994C7.54132 8.49439 7.45868 8.49439 7.41878 8.54994C7.40519 8.56886 7.39321 8.64076 7.36924 8.78457V8.78457C7.28357 9.29856 7.24074 9.55555 7.15249 9.76781C6.90524 10.3625 6.38675 10.8017 5.75951 10.9478C5.53563 11 5.26905 11 4.73591 11V11C4.32737 11 4.12309 11 3.97306 10.9515C3.58403 10.8257 3.31078 10.4757 3.28307 10.0678C3.27239 9.91044 3.32081 9.71675 3.41765 9.32938L4.62127 4.51493Z" stroke="white"/>
              </svg>
            }
            {message.type === 'cart' &&
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0587 18.5898L10.7646 14.0606" stroke="white" stroke-width="1.29408" strokeLinecap="round"/>
                <path d="M9.47 4.35938L6.88184 8.24162M18.5286 4.35938L21.1167 8.24162" stroke="white" stroke-width="1.29408" strokeLinecap="round"/>
                <path d="M15.9414 18.5898L17.2355 14.0606" stroke="white" stroke-width="1.29408" strokeLinecap="round"/>
                <path d="M4.29492 10.8281V10.8281C5.05104 10.8281 5.7057 11.3533 5.86973 12.0914L7.27641 18.4214C7.70823 20.3647 7.92415 21.3363 8.63383 21.9056C9.34352 22.4749 10.3388 22.4749 12.3295 22.4749H15.6716C17.6622 22.4749 18.6575 22.4749 19.3672 21.9056C20.0769 21.3363 20.2928 20.3647 20.7247 18.4214L22.1313 12.0914C22.2954 11.3533 22.95 10.8281 23.7061 10.8281V10.8281" stroke="white" stroke-width="1.29408" strokeLinecap="round"/>
                <path d="M23.7053 10.8304H4.29408C3.57938 10.8304 3 10.251 3 9.53627C3 8.82157 3.57938 8.24219 4.29408 8.24219H23.7053C24.42 8.24219 24.9994 8.82157 24.9994 9.53627C24.9994 10.251 24.42 10.8304 23.7053 10.8304Z" stroke="white" stroke-width="1.29408" strokeLinecap="round"/>
              </svg>              
            }
            {message.type === 'info' &&
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74561 11.999C2.74561 5.06202 5.05861 2.74902 11.9956 2.74902C18.9326 2.74902 21.2456 5.06202 21.2456 11.999C21.2456 18.936 18.9326 21.249 11.9956 21.249C5.05861 21.249 2.74561 18.936 2.74561 11.999Z" stroke="#E59F19" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.9932 8.10449V11.9995" stroke="#E59F19" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.9932 15.499H12.0028" stroke="#E59F19" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>              
            }
            {message.type === 'danger' &&
              <></>
            }
          </IconWrapper>
          <Content>
            {message.title && <Title>{message.title}</Title>}
            <Body>{message.body}</Body>
          </Content>
        </MessageContainer>
      ))}
    </MessageWrapper>
  );
};

// Функция для вызова сообщений
export const message = ({ type='default', title, body }) => {
  if (showMessage) {
    showMessage({ type, title, body });
  }
};

// Анимации для появления и исчезновения сообщения
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Styled Components
const MessageWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  min-width: 100px;
  max-width: 360px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100000;
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
  padding: 16px;
  width: auto;
  background: #2C2E32;
  color: #fff;
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
    animation: ${fadeIn} 0.5s forwards, ${fadeOut} 0.5s forwards 2.5s;
  }
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background: #565656;
  border-radius: 40px;
  overflow: hidden;
  @media (max-width: 798px) {
    min-width: 30px;
    height: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  @media (max-width: 798px) {
    font-size: 16px;
  }
`;

const Body = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #B3B3B3;
  @media (max-width: 798px) {
    font-size: 12px;
  }
`;
