import React from 'react';
import styled from 'styled-components';

const UnderConstruction = () => (
    <PageContainer>
        <MessageBox>
            <Title>Страница в разработке</Title>
            <Subtitle>Мы работаем над улучшениями. Пожалуйста, возвращайтесь позже.</Subtitle>
            <Loader />
        </MessageBox>
    </PageContainer>
);

export default UnderConstruction;

const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f7f7f7;
    font-family: 'Arial', sans-serif;
`;

const MessageBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
`;

const Title = styled.h1`
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
`;

const Subtitle = styled.p`
    color: #666;
    font-size: 1rem;
    margin-bottom: 30px;
`;

const Loader = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
