import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import moment from 'moment';

export const getAppSettings = createAsyncThunk(
    'common/getAppSettings',
    async (_, { rejectWithValue }) => {
        const currentCity = JSON.parse(localStorage.getItem('currentCity'));
        const cityId = currentCity ? currentCity.id : '18';
        const data = {
            city: {
                id: cityId,
            }
        }

        try {
            let response = await request("AppDinnerController", "GetAppSettings", data);

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    device: 'desktop',
    orientation: 'portrait',
    headerHeight: 0,

    appSettingsGetStatus: null,
    appSettings: null,
    serverTime: null,
    localTimezone: null,
    deliveryZones: [],

    isCityModalOpen: false,
    currentCity: JSON.parse(localStorage.getItem('currentCity')) || null,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAppSettings.pending, (state) => {
                state.appSettingsGetStatus = 'pending';
            })
            .addCase(getAppSettings.fulfilled, (state, action) => {
                state.appSettingsGetStatus = 'fulfilled';
                state.appSettings = action.payload;
                state.serverTime = action.payload.settings.currentTimeValue;
                state.localTimezone = moment().utcOffset() / 60;
                state.deliveryZones = action.payload.cities;
            })
            .addCase(getAppSettings.rejected, (state) => {
                state.appSettingsGetStatus = 'rejected';
            });
    },
    reducers: {
        setDevice: (state, action) => {
            state.device = action.payload;
        },
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        setHeaderHeight: (state, action) => {
            state.headerHeight = action.payload;
        },
        setIsCityModalOpen: (state) => {
            state.isCityModalOpen = true;
        },
        setIsCityModalClose: (state) => {
            state.isCityModalOpen = false;
        },
        setCurrentCity: (state, action) => {
            state.currentCity = action.payload;
        },
    },
});

export const {
    setDevice,
    setOrientation,
    setHeaderHeight,
    setIsCityModalOpen,
    setIsCityModalClose,
    setCurrentCity,
} = commonSlice.actions;

export default commonSlice.reducer;
