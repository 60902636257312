import React, { forwardRef } from 'react';
import styled from 'styled-components';

const formatPhoneNumber = (input) => {
  if (typeof input !== 'string') return '+7'; // Защита от undefined/null

  const digits = input.replace(/\D/g, ''); // Оставляем только цифры

  if (!digits.startsWith('7')) {
    return '+7'; // Если номер не начинается с 7, возвращаем только "+7"
  }

  let formatted = '+7 ';
  if (digits.length > 1) formatted += `${digits.slice(1, 4)}`;
  if (digits.length >= 4) formatted += ` ${digits.slice(4, 7)}`;
  if (digits.length >= 7) formatted += ` ${digits.slice(7, 9)}`;
  if (digits.length >= 9) formatted += ` ${digits.slice(9, 11)}`;

  return formatted.trim();
};

const unformatPhoneNumber = (input) => {
  if (typeof input !== 'string') return '+7'; // Защита от undefined/null
  const digits = input.replace(/\D/g, ''); // Удаляем все нецифровые символы
  return digits.length > 1 ? `+${digits}` : '+7'; // Если что-то есть, добавляем "+"
};

const Input = forwardRef(({ type = 'text', size, width, value, placeholder, onChange, onKeyDown, ...props }, ref) => {
  const handlePhoneChange = (e) => {
    const rawValue = unformatPhoneNumber(e.target.value);
    onChange(rawValue); // Сохраняем только цифры с +7
  };

  if (type === 'phone') {
    return (
      <StyledInput
        type="tel"
        ref={ref}
        size={size}
        width={width}
        value={formatPhoneNumber(value || '')} // Форматируем отображаемое значение
        onChange={(e) => handlePhoneChange(e, onChange)}
        onKeyDown={onKeyDown}
        placeholder="+7 (___) ___ __ __"
        maxLength={16}
        inputMode="numeric"
        {...props}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <StyledTextArea
        ref={ref}
        size={size}
        width={width}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        {...props}
      />
    );
  }

  return (
    <StyledInput
      type={type}
      ref={ref}
      size={size}
      width={width}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      {...props}
    />
  );
});

export default Input;

const StyledInput = styled.input`
  display: flex;
  width: 100%;
  max-width: ${({ width }) => (width ? '400px' : 'auto')};
  min-height: ${({ size }) => (size === 'small' ? '50px' : '60px')};
  padding: 0 24px;
  background: #FBFBFB;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  transition: border .2s ease;
  &:hover {
    border: 1px solid #AAA8A8;
  }
  &:focus {
    border: 1px solid #4CC76E;
  }
  @media (max-width: 798px) {
    min-height: 45px;
    font-size: 16px;
  }
`;

const StyledTextArea = styled.textarea`
  display: flex;
  width: 100%;
  min-width: 250px;
  max-width: ${({ width }) => (width ? '400px' : 'auto')};
  height: ${({ size }) => (size === 'small' ? '100px' : '150px')};
  min-height: ${({ size }) => (size === 'small' ? '50px' : '60px')};
  padding: 12px 24px;
  background: #FBFBFB;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  transition: border .2s ease;
  resize: none;

  &:hover {
    border: 1px solid #AAA8A8;
  }

  &:focus {
    border: 1px solid #4CC76E;
  }
`;
