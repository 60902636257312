import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPartner } from '../redux/reducer/slices/partnerSlice';

const usePartner = () => {
    const dispatch = useDispatch();
    const hostname = window.location.hostname.toLowerCase();
    const subdomain = hostname.split('.')[0];

    const partnersList = {
        kushatpodano: 202865,
        domashnieobedi: 224542,
        combolanch: 239665,
        maxfood: 224330,
        dom51: 210106,
        blinok: 234387,
        hitlanch: 220546,
        gost: 212341,
        edawok: 234936,

        // Пенза
        sokol: 242757,
        'chasovoistolova.ru': 242974, // 195.161.62.100 https://chasovoistolova.ru/
        ya_novaya_stolovaya: 240398,

        // Балаково
        imperia: 235836,
        stol1: 235425,
        'stolov1.ru': 235425, // поправь тут

        // Димитровград
        kompot: 233637,
    };

    useEffect(() => {
        const partnerKey = partnersList[hostname] || partnersList[subdomain];

        if (partnerKey) {
            dispatch(setCurrentPartner(partnerKey));
        } else {
            dispatch(setCurrentPartner(null));
        }
    }, [hostname, subdomain, dispatch]);
};

export default usePartner;
