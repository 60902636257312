import { useEffect, useState } from "react";

const INACTIVITY_TIME = 5 * 60 * 1000; // 5 минут

export const useInactivity = () => {
    const [inactivity, setInactivity] = useState(false);
    let timer = null;

    const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setInactivity(true);
        }, INACTIVITY_TIME);
    };

    useEffect(() => {
        resetTimer();

        const events = ["mousemove", "keydown", "scroll", "click"];

        events.forEach(event => window.addEventListener(event, resetTimer));

        return () => {
            clearTimeout(timer);
            events.forEach(event => window.removeEventListener(event, resetTimer));
        };
    }, []);

    return { inactivity };
};