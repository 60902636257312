import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPartner: null,
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setCurrentPartner: (state, action) => {
            state.currentPartner = action.payload;
        },
    },
});

export const {
    setCurrentPartner,
} = partnerSlice.actions;

export default partnerSlice.reducer;
