import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import { modifyOrder, setPayedOrder } from './cartSlice';
import { addPaymentLink, setPayUrl } from './checkSlice';
import { message } from '../../../components/Message';
import ym from 'react-yandex-metrika';

export const getOrders = createAsyncThunk(
    'order/getOrders',
    async (_, { rejectWithValue }) => {
        try {
            let response = await request("AppDinnerController", "GetOrders1");

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const saveOrder = createAsyncThunk(
    'orders/saveOrder',
    async ({ company, date, owner, paysystem, period, products, wallet, receipt, numberOrderCheck }, { getState, dispatch, rejectWithValue }) => {
        const { orders } = getState().orders;
        const ordersCount = orders.orders?.length;

        try {
            let data = {
                "company": { "id": company },
                "date": { "dateString": date },
                "order": { "id": 0 },
                "owner": { "id": owner },
                "paysystem": { "id": paysystem },
                "period": { "id": period },
                "products": products,
                "shortchange": "",
                "Receipt": receipt,
            };

            if (wallet && wallet.id) {
                data["wallet"] = wallet;
            }

            let response = await request("DinnerController", "SaveOrder", data);

            if (response.result === 1 && !response.data.errors) {
                dispatch(modifyOrder({
                    numberOrderCheck: numberOrderCheck,
                    savedOrder: {...response.data}
                }));
                ym('reachGoal','new_order');

                if (ordersCount === 0) {
                    ym('reachGoal','first_order');
                }

                if(response.data.paysystem.id === '32') {
                    dispatch(setPayedOrder(numberOrderCheck));
                }

                if (response.data.paysystem.id === '2' || response.data.paysystem.id === '16') {
                    let response_register = await request("PaySystemService", "RegisterOrder", {
                        orders: [{ "id": response.data.order.id }],
                        paysystem: { "id": response.data.paysystem.id },
                        cardId:  '',
                        domain: window.location.origin,
                    });

                    console.log(response_register, 'response_register');

                    if (response_register.result === 1) {
                        console.log(response_register.data.formUrl, response.data.order.id, numberOrderCheck);
                        dispatch(addPaymentLink({
                            orderId: response.data.order.id,
                            numberOrderCheck,
                            payUrl: response_register.data.formUrl,
                        }));
                        dispatch(setPayUrl(response_register.data.formUrl));
                    }
                }
                
                return response.data;
            } else if (response.data.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
                console.log(response.data.errors[0].description);
                return rejectWithValue(response.data.errors[0].description);
            } else if (response.data.message) {
                console.log(response.data.message);
                return rejectWithValue(response.data.message);
            } else {
                const errorMessage = 'Непредвиденная ошибка SaveOrder';
                return rejectWithValue(errorMessage);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const cancelOrder = createAsyncThunk(
    'order/cancelOrder',
    async (data, { rejectWithValue }) => {
        console.log(data);
        try {
            let response = await request('DinnerController', 'CancelOrder', data);

            if (response.result) {
                message({type: 'default', title: 'Заказ удален', body: `Заказ №${data.orderId} успешно удален`});
                return data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const getOrderReview = createAsyncThunk(
    'order/getOrderReview',
    async (orderId, { rejectWithValue }) => {
        try {
            let response = await request('ReviewsController', 'GetOrderReview', orderId);

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const reviewOrder = createAsyncThunk(
    'order/reviewOrder',
    async (data, { rejectWithValue }) => {
        try {
            let response = await request('ReviewsController', 'SaveOrderReview', {review: {items: data}});

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    getOrdersStatus: null,
    orders: [],
    saveOrderStatus: null,
    cancelOrderStatus: null,

    lastOrderData: null,

    getOrderReviewStatus: null,
    orderReview: null,
    reviewOrderStatus: null,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    extraReducers: (builder) => {
        builder
            // getOrders
            .addCase(getOrders.pending, (state) => { state.getOrdersStatus = 'pending'; })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.getOrdersStatus = 'fulfilled';
                state.orders = action.payload;
            })
            .addCase(getOrders.rejected, (state) => { state.getOrdersStatus = 'rejected'; })

            // saveOrder
            .addCase(saveOrder.pending, (state) => { state.saveOrderStatus = 'pending'; })
            .addCase(saveOrder.fulfilled, (state, action) => {
                state.saveOrderStatus = 'fulfilled';
                state.newSaveOrder = action.payload;
                if (action.payload.paysystem.id === '32') {
                    state.isNewSaveOrderPayed = true;
                }
            })
            .addCase(saveOrder.rejected, (state) => { state.saveOrderStatus = 'rejected'; })

            // cancelOrder
            .addCase(cancelOrder.pending, (state) => { state.cancelOrderStatus = 'pending'; })
            .addCase(cancelOrder.fulfilled, (state, action) => {
                state.cancelOrderStatus = 'fulfilled';
                state.orders = {
                    ...state.orders,
                    orders: state.orders.orders.filter((order) => +order.id !== +action.payload.orderId)
                };
            })
            .addCase(cancelOrder.rejected, (state) => { state.cancelOrderStatus = 'rejected'; })

            // getOrderReview
            .addCase(getOrderReview.pending, (state) => { state.getOrderReviewStatus = 'pending'; })
            .addCase(getOrderReview.fulfilled, (state, action) => {
                state.getOrderReviewStatus = 'fulfilled';
                state.orderReview = action.payload;
            })
            .addCase(getOrderReview.rejected, (state) => { state.getOrderReviewStatus = 'rejected'; })

            // reviewOrder
            .addCase(reviewOrder.pending, (state) => { state.reviewOrderStatus = 'pending'; })
            .addCase(reviewOrder.fulfilled, (state) => { state.reviewOrderStatus = 'fulfilled'; })
            .addCase(reviewOrder.rejected, (state) => { state.reviewOrderStatus = 'rejected'; })
    },
    reducers: {
        setlastOrderData: (state, action) => { state.lastOrderData = action.payload; },
    },
});

export const {
    setlastOrderData,
} = ordersSlice.actions;

export default ordersSlice.reducer;