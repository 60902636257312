import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import squareLogo from "../../../images/square-logo.png";
import { CloseIcon } from "../../../components/Icons";

const AppReco = ({ onClose }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/app');
        onClose();
        localStorage.setItem('appRecoShowed', 'true');
    };

    const handleClose = (e) => {
        e.stopPropagation();
        onClose();
        localStorage.setItem('appRecoShowed', 'true');
    };

    return (
        <Wrapper onClick={handleClick}>
            <CloseButton onClick={handleClose}><CloseIcon/></CloseButton>
            <Image src={squareLogo} alt='app-logo' />
            <Description>Приложение "Сытый"</Description>
            <Link>Скачать</Link>
        </Wrapper>
    );
};

export default AppReco;

const Wrapper = styled.div`
    position: relative;
    display: flex;
	align-items: center;
	width: fit-content;
	gap: 8px;
	padding: 10px;
	width: 100%;
	background-color: #f1f1f1;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 8px;
    svg {
        width: 20px;
        height: 20px;
    }
`;

const Image = styled.img`
    width: 45px;
    height: 45px;
    object-fit: contain;
`;

const Description = styled.p`
    font-size: 12px;
    font-weight: 600;
`;

const Link = styled.button`
    margin: auto 0 0 auto;
    color: var(--color-accent);
    text-transform: uppercase;
`;