import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SitiyRouter, PartnerRouter } from "./Router";
import { GlobalStyle } from './styles';
import { YMInitializer } from 'react-yandex-metrika';
import moment from "moment";
import useDeviceInfo from "../hooks/useDeviceInfo";
import usePartner from "../hooks/usePartner";
import MainLayout from "./layouts/MainLayout";
import PartnerLayout from "./layouts/PartnerLayout";
import { getAppSettings, setIsCityModalOpen } from "../redux/reducer/slices/commonSlice";
import { getProfile } from "../redux/reducer/slices/profileSlice";
import { getCompanies } from "../redux/reducer/slices/companiesSlice";
import { getOrders } from "../redux/reducer/slices/ordersSlice";
import { ScrollProvider } from "../hooks/ScrollContext";
import { getKitchens, getMenu, setCurrentKitchen } from "../redux/reducer/slices/kitchensSlice";
import { DraggableBox } from "../components/DraggableBox";

const App = () => {
    useDeviceInfo();
    usePartner();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isDev = searchParams.get('dev') === 'true';
    const dispatch = useDispatch();
    const { device, orientation, currentCity } = useSelector((state) => state.common);
    const { isAuth } = useSelector((state) => state.profile);
    const { currentPartner } = useSelector((state) => state.partner);

    if (device === 'mobile' && orientation === 'portrait') {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        
        window.addEventListener('resize', setVh);
        setVh();
    }

    useEffect(() => {
        dispatch(getAppSettings());

        if (!currentCity && location.pathname !== '/app') {
            dispatch(setIsCityModalOpen());
        }

        if (isAuth) {
            dispatch(getProfile());
            dispatch(getCompanies());
            dispatch(getOrders());
        }
    }, [isAuth]);
    
    return (
        <>
            <GlobalStyle/>
            <YMInitializer
                accounts={[50456719]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                }}
                version="2"
            />

            <ScrollProvider>
                {currentPartner ? (
                    <PartnerLayout>
                        <PartnerRouter/>
                    </PartnerLayout>
                ) : (
                    <MainLayout>
                        <SitiyRouter/>
                    </MainLayout>
                )}
            </ScrollProvider>

            {isDev && (
                <DraggableBox/>
            )}
        </>
    );
};

export default App;
