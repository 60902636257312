import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UnderConstruction from '../components/UnderConstruction';

const MainPage = React.lazy(() => import('../containers/mainPage/MainPage'));
const MenuPage = React.lazy(() => import('../containers/menuPage/MenuPage'));
const CartPage = React.lazy(() => import('../containers/cartPage/CartPage'));
const ProfilePage = React.lazy(() => import('../containers/profilePage/ProfilePage'));
// const AboutPage = React.lazy(() => import('../containers/aboutPage/AboutPage'));
const DeliveryInfoPage = React.lazy(() => import('../containers/deliveryInfoPage/DeliveryInfoPage'));
const KitchensPage = React.lazy(() => import('../containers/kitchensPage/KitchensPage'));
// const PartnersPage = React.lazy(() => import('../containers/partnersPage/PartnersPage'));
const PricePage = React.lazy(() => import('../containers/pricePage/PricePage'));
const TestdrivePage = React.lazy(() => import('../containers/testdrivePage/TestdrivePage'));
const InteractiveMapPromoModule = React.lazy(() => import('../interactiveMapPromoModule/InteractiveMapPromoModule'));
const NotFoundPage = React.lazy(() => import('../containers/notFoundPage/NotFoundPage'));

export const SitiyRouter = () => {
    const { isAuth } = useSelector(state => state.profile);

    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/menu/:id" element={<MenuPage/>}/>
                <Route path="/cart" element={isAuth ? <CartPage/> : <Navigate to="/"/>}/>
                <Route path="/profile" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                <Route path="/profile/:tab" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                <Route path="/about" element={<UnderConstruction/>}/>
                <Route path="/delivery" element={<DeliveryInfoPage/>}/>
                <Route path="/kitchens" element={<KitchensPage/>}/>
                <Route path="/organizations" element={<UnderConstruction/>}/>
                <Route path="/app" element={<InteractiveMapPromoModule/>}/>
                <Route path="/price" element={<PricePage/>}/>
                <Route path="/testdrive" element={<TestdrivePage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        </Suspense>
    );
};

export const PartnerRouter = () => {
    const { isAuth } = useSelector(state => state.profile);

    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<MenuPage/>}/>
                <Route path="/cart" element={isAuth ? <CartPage/> : <Navigate to="/"/>}/>
                <Route path="/profile" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                <Route path="/profile/:tab" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                <Route path="/about" element={<UnderConstruction/>}/>
                <Route path="/delivery" element={<UnderConstruction/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        </Suspense>
    );
};
