import React, { useState, useRef } from 'react';

const PinInput = ({ length = 4, onComplete, inputStyle }) => {
    const [pin, setPin] = useState(Array(length).fill(''));
    const [focusedIndex, setFocusedIndex] = useState(0);

    // Массив для хранения ссылок на каждый input
    const inputRefs = useRef([]);

    // Обработчик изменения ввода
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value) || value === '') {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);

            // Move to the next input field
            if (value !== '' && index < length - 1) {
                setFocusedIndex(index + 1);
                inputRefs.current[index + 1].focus();
            }

            // Когда PIN полностью введен, вызвать onComplete
            if (newPin.every((digit) => digit !== '')) {
                onComplete(newPin.join(''));
            }
        }
    };

    // Обработчик фокуса на поле ввода
    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    // Обработчик удаления символа (backspace)
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && pin[index] === '' && index > 0) {
            setFocusedIndex(index - 1);
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            {pin.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onFocus={() => handleFocus(index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength={1}
                    inputMode="numeric"
                    ref={(el) => (inputRefs.current[index] = el)}
                    style={{
                        ...inputStyle,
                        border: 'solid 1px #EAEAEA',
                        textAlign: 'center',
                        outline: 'none',
                    }}
                />
            ))}
        </div>
    );
};

export default PinInput;
