import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ArrowBackIcon, BagIcon, CaseIcon, ChevronForvardIcon, CloseIcon, InfoCircleIcon, KitchenIcon, LogoIcon, LogoutIcon, PlacemarkIcon, PlanetIcon, ProfileIcon, SettingsIcon, SmileIcon, TicketIcon, WalletIcon } from '../../../components/Icons';
import Button from "../../../components/Button";
import FloatingSocialButtons from "../../../components/FloatingSocialButtons";
import { Container, SectionBottom, SectionTop } from "../../../core/styles";
import Title from "../../../components/Title";
import { logout } from "../../../redux/reducer/slices/profileSlice";

const MobileSidebar = ({isOpen, onClose}) => {
	const location = useLocation();
	const navigate = useNavigate();
    const dispatch = useDispatch();
	const { isAuth, profile } = useSelector((state) => state.profile);
	const currentParams = new URLSearchParams(location.search);
    const { currentPartner } = useSelector((state) => state.partner);
    const [isProfileSubMenuOpen, setIsProfileSubMenuOpen] = useState(false);

	const menuItems = [
		{ key: '/about', icon: <SmileIcon/>, text: 'О сервисе' },
		{ key: '/delivery', icon: <InfoCircleIcon/>, text: 'Условия доставки' },
		!currentPartner && { key: '/organizations', icon: <CaseIcon/>, text: 'Организациям' },
		!currentPartner && { key: '/kitchens', icon: <KitchenIcon/>, text: 'Кухням' },
		!currentPartner && { key: 'https://live.sitiy.ru/', icon: <PlanetIcon/>, text: 'Заказы live', external: true },
	].filter(Boolean);

    const profileSubMenuItems = [
        { key: '/profile/orders', icon: <BagIcon/>, text: 'Мои заказы' },
        { key: '/profile/finances', icon: <WalletIcon/>, text: 'Мои баллы' },
        { key: '/profile/addresses', icon: <PlacemarkIcon/>, text: 'Мои адреса' },
        !currentPartner && { key: '/profile/promocodes', icon: <TicketIcon />, text: 'Личный промокод' },
        { key: '/profile/settings', icon: <SettingsIcon/>, text: 'Настройки' },
    ].filter(Boolean);

	const handleOpenSubMenu = () => {
        setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
    }
	
	const handleLinkClick = (key) => {
		navigate({ pathname: key, search: currentParams.toString() });
		onClose();
	}

    const handleLogout = () => {
        dispatch(logout());
        onClose();
    }

	return (
		<>
            {isOpen &&
                <MobileSidebarWrapper>
                    <SidebarHeader>
                        <Button style={{flex: 0}} size='small' type='icon' onClick={onClose}><CloseIcon width={24} height={24}/></Button>
                    </SidebarHeader>
                    <SectionTop style={{minHeight: 'fit-content'}}>
                        <Container>
                            {isAuth &&
                                <MenuProfile>
                                    <MenuProfileInfo>
                                        {profile?.client?.name && <Title>{profile.client.name}</Title>}
                                        {profile?.client?.phone && <p>{profile.client.phone}</p>}
                                    </MenuProfileInfo>
                                    <Button style={{flex: 0}} size='small' type='icon' onClick={handleLogout}><LogoutIcon/></Button>
                                </MenuProfile>
                            }
                        </Container>
                    </SectionTop>
                    <SectionBottom style={{height: '100%'}}>
                        <Container>
                            <MenuList>
                                { isAuth &&
                                    <>
                                        <MenuItem onClick={handleOpenSubMenu}>
                                            <ProfileIcon/>
                                            <Text>Профиль</Text>
                                            <ChevronIconWrapper isOpen={isProfileSubMenuOpen}>
                                                <ChevronForvardIcon width={14} height={14} />
                                            </ChevronIconWrapper>
                                        </MenuItem>
                                        {isProfileSubMenuOpen && (
                                            <SubMenuList>
                                                {profileSubMenuItems.map((item, index) => (
                                                    <SubMenuItem key={index} onClick={() => handleLinkClick(item.key)}>
                                                        {item.icon}
                                                        <Text>{item.text}</Text>
                                                        <ChevronForvardIcon width={14} height={14} />
                                                    </SubMenuItem>
                                                ))}
                                            </SubMenuList>
                                        )}
                                    </>
                                }
                                {menuItems.map((item, index) => (
                                    item.external ? (
                                        <MenuItem as="a" key={index} href={item.key} target="_blank" rel="noopener noreferrer">
                                            {item.icon}
                                            <Text>{item.text}</Text>
                                            <ChevronForvardIcon width={14} height={14} />
                                        </MenuItem>
                                    ) : (
                                        <MenuItem key={index} onClick={() => handleLinkClick(item.key)}>
                                            {item.icon}
                                            <Text>{item.text}</Text>
                                            <ChevronForvardIcon width={14} height={14} />
                                        </MenuItem>
                                    )
                                ))}
                            </MenuList>
                        </Container>
                    </SectionBottom>
                </MobileSidebarWrapper>
            }
		</>
	);
}

export default MobileSidebar;

const MobileSidebarWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 900;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	background-color: #f8f8f8;
    
`;

const SidebarHeader = styled.div`
	display: flex;
	justify-content: flex-end;
    align-items: center;
	gap: 16px;
	padding: 24px 10px 12px;
	background-color: #ffffff;
`;

const MenuProfile = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;
`;

const MenuProfileInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	p {
		color: #969696;
	}
`;

const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-right: 5px;
    height: calc(100vh - 250px);
    overflow-y: auto;
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
	min-height: 50px;
    background-color: #ffffff;
    border-radius: 16px;
	box-shadow: 0px 8px 25px 0px #C1C1C140;
    color: var(--color-font);
    cursor: pointer;
    transition: all 0.3s ease;
`;

const ChevronIconWrapper = styled.div`
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

const SubMenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 24px;
`;

const SubMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    min-height: 40px;
    background-color: #eeeeee;
    border-radius: 12px;
	box-shadow: 0px 8px 25px 0px #C1C1C140;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #e7e7e7;
    }
`;

const Text = styled.p`
    width: 100%;
`;