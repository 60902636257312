import { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }) => {
    const wrapperRef = useRef(null);

    return (
        <ScrollContext.Provider value={{ wrapperRef }}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = () => useContext(ScrollContext);
