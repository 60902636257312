import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendPhone, sendCode, resetAuthState } from '../../../redux/reducer/slices/profileSlice';
import styled from "styled-components"
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import PinInput from '../../../components/PinInput';
import Title from '../../../components/Title';

const AuthForm = () => {
  const {
    sendPhoneStatus,
    isCodeValid,
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState(+7);
  const [time, setTime] = useState(60);
  const phoneInputRef = useRef(null);
  let idInterval = null;

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    return () => clearInterval(idInterval);
  }, []);

  const onChangePhone = (value) => {
    setPhone(value);
  }

  const handleSendPhone = () => {
    dispatch(sendPhone(phone));

    idInterval = setInterval(() => {
      setTime((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearTimeout(idInterval)
          setTime(60);
          dispatch(resetAuthState())
        }
      });
    }, 1000)
  }

  const onChangeCode = (code) => {
    if (code.length === 4) {
      dispatch(sendCode(code));
    }
  }

  const handleEditPhone = () => {
    dispatch(resetAuthState());
  };

  const handlePhonePushEnter = (event) => {
    if (event.key === 'Enter' && phone.length === 12) {
      handleSendPhone();
    }
  };

  return (
    <Wrapper>
      <Title>Вход на сайт</Title>
      {sendPhoneStatus !== 'fulfilled' ? (
        <Form>
          <FormItem>
            <FormLabel>Номер телефона</FormLabel>
            <Input
              type="phone"
              placeholder="+7"
              value={phone}
              onChange={onChangePhone}
              onKeyDown={handlePhonePushEnter}
              ref={phoneInputRef}
            />
          </FormItem>
          <FormItem>
            <Button
              type='primary'
              onClick={handleSendPhone}
              disabled= {phone.length !== 12}
            >
              Выслать код
            </Button>
          </FormItem>
        </Form>
      ) : (
        <Form>
          <FormItem>
            <FormLabel>
              <p>
                Код отправили сообщением<br/>
                на номер <b onClick={handleEditPhone} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{phone}</b>
              </p>
            </FormLabel>
            <PinContainer>
              <PinInput
                  length={4}
                  focus
                  onComplete={onChangeCode}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                      width: '60px',
                      height: '60px',
                      margin: '5px',
                      fontSize: '24px',
                      borderRadius: '16px',
                      borderColor: isCodeValid === false ? '#EA394B' : isCodeValid === true ? '#49BC9A' : '#EAEAEA',
                      backgroundColor: '#F9F9F9',
                  }}
              />
              <PinError>{isCodeValid === false ? 'код неверный' : ''}</PinError>
            </PinContainer>
          </FormItem>

          <FormItem>
            <Button
              type='primary'
              onClick={handleSendPhone}
              disabled= {sendPhoneStatus === 'fulfilled'}
            >
              {sendPhoneStatus === 'fulfilled' ? `Получить новый код, ${time} сек` : `Получить новый код` }
            </Button>
          </FormItem>
        </Form>
      )}
      <UserAgreement>
        Продолжая, вы принимаете условия &nbsp;
        <br/>
        <a href="https://menuforme.online/files/146923/static/userpolicy.html?time=164396005052412" target="_blank" rel="noopener noreferrer">
          <span style={{ color: '#54933F', textDecoration: 'underline', cursor: 'pointer' }}>“Пользовательским соглашением”</span>
        </a>
      </UserAgreement>
    </Wrapper>
  );
};

export default AuthForm;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 100%;
`;

const Form = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #2C2E32;
`;

const UserAgreement = styled.div`
  margin-top: 16px;
`;

const PinContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 16px;
`;

const PinError = styled.div`
    height: 16px;
    color: #EA394B;
`;