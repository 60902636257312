import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthModalClose, setIsAuthQuestionModalClose } from '../../redux/reducer/slices/profileSlice';
import { setIsAddressModalClose } from "../../redux/reducer/slices/companiesSlice";
import { Wrapper, Content } from '../styles';
import { Message } from "../../components/Message";
import Modal from "../../components/Modal";
import AuthForm from "../../containers/forms/authForm/AuthForm";
import AddressSelectorForm from "../../containers/forms/addressSelectorForm/AddressSelectorForm";
import AuthQuestionForm from "../../containers/forms/authForm/AuthQuestionForm";
import { setGeocodeAddress, setGeocodeCoords } from "../../redux/reducer/slices/mapSlice";
import styled from "styled-components";
import { useScroll } from "../../hooks/ScrollContext";
import Header from "../../containers/header/Header";

const PartnerLayout = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentKitchen } = useSelector((state) => state.kitchens);
    const { isAuthModalOpen, isAuthQuestionModalOpen } = useSelector((state) => state.profile);
	const { isAddressModalOpen, saveCompanyStatus } = useSelector((state) => state.companies);
    const { wrapperRef } = useScroll();

    // Сбрасываем скролл на всех страницах кроме главной
    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTo(0, 0);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (saveCompanyStatus === 'fulfilled') {
            dispatch(setIsAddressModalClose());
        }
    }, [saveCompanyStatus]);

    return (
        <>
            <Wrapper ref={wrapperRef}>
                <Header/>

                <Content style={{paddingTop: location.pathname === '/app' || location.pathname === '/price' ? '0' : null}}>
                    {children}
                </Content>

                <FooterWrapper>
                    <p>&copy; {new Date().getFullYear()} {currentKitchen?.name}</p>
                </FooterWrapper>
            </Wrapper>

            <Modal isOpen={isAuthModalOpen} onClose={() => {dispatch(setIsAuthModalClose())}}><AuthForm/></Modal>
            <Modal isOpen={isAddressModalOpen} onClose={() => {dispatch(setIsAddressModalClose()); dispatch(setGeocodeAddress(null)); dispatch(setGeocodeCoords(null));}}><AddressSelectorForm/></Modal>
            <Modal isOpen={isAuthQuestionModalOpen} onClose={() => {dispatch(setIsAuthQuestionModalClose())}}><AuthQuestionForm/></Modal>

            <Message/>
        </>
    );
};

export default PartnerLayout;

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #222;
  color: #fff;
  font-size: 14px;
  text-align: center;
`;
